import React, { useEffect, useState } from "react";
import ripple from "../assets/images/our_dogs/ripple.JPG";
import ripple_2 from "../assets/images/our_dogs/ripple_2.JPG";
import chiquita from "../assets/images/our_dogs/chiquita.JPG";
import chiquita_2 from "../assets/images/our_dogs/chiquita_3.JPG";

function Animals() {
  const [animals, setAnimals] = useState([]);
  const [loading, setLoading] = useState(true);

  const organizationNames = {
    CA3151: "Shiloh's Rescue",
    CA3021: "Novy's Ark",
    CA3091: "Atlas Rescue",
  };

  const ourAnimals = [
    {
      src: ripple,
      alt: "Ripple",
      src2: ripple_2,
      alt2: "Ripple 2",
      house_trained: true,
      spayed_neutered: false,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Ripple",
      species: "Dog",
      description:
        "Ripple is an affectionate and playful pup with a heart full of love! She adores giving kisses, snuggling up for cuddles, and enjoys being around people of all ages. Ripple gets along wonderfully with other dogs, cats, and children, making her a perfect companion for any family. She’s house-trained, well-behaved, and even loves adventures like paddleboarding or playing at the beach. Rescued from a high-kill shelter in Kern County after being found as a stray with her baby, Ripple has overcome a challenging start and is now ready to find her forever home. Could you be the one to give Ripple the loving family she deserves?",
      size: "33 lbs",
      age: "1.5 years old",
      gender: "Female",
      breed: "Terrier Mix",
      location: "Atascadero, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: chiquita,
      alt: "Chiquita",
      src2: chiquita_2,
      alt2: "Chiquita 2",
      house_trained: true,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Chiquita",
      species: "Dog",
      description:
        "Rescued from Kern County shelter, Chiquita is a sweet and gentle soul ready to find her forever family! This small-to-medium-sized beauty is the perfect package of love and charm, with baby puppy-dog eyes that will melt your heart instantly. Chiquita is fantastic in the car and gets along wonderfully with everyone—she’s great with cats, dogs, and kids alike. Though she may be a little shy at first, her sweetness shines through as soon as she feels comfortable. Her gentle nature makes her an ideal companion for families or anyone looking for a calm and loving furry friend. If you’re searching for a quiet, affectionate, and oh-so-cute dog to complete your family, Chiquita could be the one! Apply to meet Chiquita today—she can’t wait to meet you!",
      size: "26 lbs",
      age: "1.5 years old",
      gender: "Female",
      breed: "Terrier Mix",
      location: "Los Osos, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    // Add more animals here
  ];

  useEffect(() => {
    const fetchAnimals = async () => {
      try {
        const organizationIds = "CA3021,CA3151,CA3091";
        const response = await fetch(
          `https://api.petfinder.com/v2/animals?organization=${organizationIds}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PETFINDER_ACCESS_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setAnimals(data.animals);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching animals:", error);
        setLoading(false);
      }
    };

    fetchAnimals();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Our Available Animals</h2>
      <p>
        These animals are available for adoption through Paddling Paws. If you
        are interested in adopting one of our animals, please fill out our{" "}
        <a href="/adopt">Adoption Application</a>.
      </p>
      <div style={styles.gridContainer}>
        {ourAnimals.map((animal) => (
          <div key={animal.name} style={styles.gridItem}>
            <h3>{animal.name}</h3>
            <p>{animal.species}</p>
            <img src={animal.src} alt={animal.alt} style={styles.image} />
            <p>
              <strong>Gender:</strong> {animal.gender}
            </p>
            <p>
              <strong>Size:</strong> {animal.size}
            </p>
            <p>
              <strong>Age:</strong> {animal.age}
            </p>
            <p>
              <strong>Breed:</strong> {animal.breed}
            </p>
            <p>
              <strong>Location:</strong> {animal.location}
            </p>
            {animal.house_trained && <p>✔ Housetrained</p>}
            {animal.spayed_neutered && (
              <p>✔ {animal.gender === "Female" ? "Spayed" : "Neutered"}</p>
            )}
            {animal.good_with_dogs && <p>✔ Good with other dogs</p>}
            {animal.good_with_cats && <p>✔ Good with cats</p>}
            {animal.good_with_children && <p>✔ Good with children</p>}
            <img src={animal.src2} alt={animal.alt2} style={styles.image} />
            <p>{animal.description}</p>
            <p>
              <strong>If Interested:</strong>
            </p>
            <p>
              Apply to adopt {animal.name} with our{" "}
              <a href="/adopt" target="_blank" rel="noopener noreferrer">
                Adoption Application
              </a>
            </p>
          </div>
        ))}
      </div>

      <h2>
        Available Animals from Other Local Organizations on the Central Coast
      </h2>
      <p>
        If you are interested in any of the pets below, please reach out
        directly to these rescues for steps to apply to adopt.
      </p>
      {loading ? (
        <p>Loading animals...</p>
      ) : (
        <div style={styles.gridContainer}>
          {animals.length > 0 ? (
            animals.map((animal) => (
              <div key={animal.id} style={styles.gridItem}>
                <h3>{animal.name}</h3>
                <p>{animal.species}</p>
                {animal.photos.length > 0 && (
                  <img
                    src={animal.photos[0]?.medium}
                    alt={animal.name}
                    style={styles.image}
                  />
                )}
                <p>
                  <strong>Organization:</strong>{" "}
                  {organizationNames[animal.organization_id]}
                </p>
                <p>{animal.description}</p>
                <p>
                  <strong>Gender:</strong> {animal.gender}
                </p>
                <p>
                  <strong>Size:</strong> {animal.size}
                </p>
                <p>
                  <strong>Age:</strong> {animal.age}
                </p>
                <p>
                  <strong>Breed:</strong> {animal.breeds.primary}
                </p>
                <p>
                  <strong>Location:</strong> {animal.contact.address.city},{" "}
                  {animal.contact.address.state}
                </p>
                {animal.attributes.house_trained && <p>✔ Housetrained</p>}
                {animal.attributes.spayed_neutered && <p>✔ Spayed/Neutered</p>}
                {animal.environment.dogs && <p>✔ Good with other dogs</p>}
                {animal.environment.cats && <p>✔ Good with cats</p>}
                {animal.environment.children && <p>✔ Good with children</p>}
                {animal.contact.email && (
                  <p>
                    <strong>Email:</strong> {animal.contact.email}
                  </p>
                )}
                {animal.contact.phone && (
                  <p>
                    <strong>Phone:</strong> {animal.contact.phone}
                  </p>
                )}
              </div>
            ))
          ) : (
            <p>No animals available at the moment.</p>
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "20px",
  },
  gridItem: {
    flex: "1 1 calc(33.333% - 20px)",
    boxSizing: "border-box",
    margin: "10px",
    padding: "10px",
    border: "3px solid #5180C0",
    borderRadius: "8px",
    maxWidth: "300px",
    lineHeight: "1.5em", // Added for consistent text spacing
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    marginBottom: "10px", // Added for consistent spacing under images
  },
};

export default Animals;
