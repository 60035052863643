import React from "react";

function Interest() {
  return (
    <div style={{ padding: "20px", textAlign: "center"  }}>
      <h2>Interested?</h2>
      <p>
        If you're interested in joining our email list or helping out Paddling Paws, please fill out
        the form below:
      </p>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScaNWc4Tvs2DzKbiSpWJDo8KjQUxZHxFc5JpMbzOzbJr0iWRA/viewform?embedded=true"
        width="100%"
        height="1266"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Paddling Paws Interest Form"
      >
        Loading…
      </iframe>
    </div>
  );
}

export default Interest;
