import React from 'react';
import { Link } from 'react-router-dom';
import icon_logo from '../assets/images/icon_blue.png';
import NavBar from './NavBar';

function Header() {
    return (
        <header className="header">
            <div className="header-logo">
                <Link to="/">
                    <img src={icon_logo} alt="Paddling Paws Logo" />
                </Link>
            </div>
            <div className="header-content">
                <div className="header-text">
                    <h1 className="main-title">Paddling Paws</h1>
                    <h3 className="subtitle">Where Rescue Meets the Coast.</h3>
                </div>
                <NavBar />
            </div>
        </header>
    );
}

export default Header;
