import React from "react";
import { Helmet } from "react-helmet";

import NWD_logo from "../assets/images/sponsor_logos/NWD_logo.png";
import SilverOak_logo from "../assets/images/sponsor_logos/silveroak.png";
import Masterguard_logo from "../assets/images/sponsor_logos/MasterguardLogo.png"

function Sponsors() {
  const sponsorLogos = [
    {
      src: NWD_logo,
      alt: "NWD",
      link: "https://www.nicoleweberdesign.com/",
    },
    {
      src: SilverOak_logo,
      alt: "Silver Oak Construction",
      link: "https://silveroakcon.com/",
    },
    {
      src: Masterguard_logo,
      alt: "Masterguard",
      link: "https://www.amazon.com/stores/page/637AD7AC-2C57-4BD0-BDC5-95462B973357?ref_=cm_sw_r_apin_ast_store_A15P9YHG1NT3TK3BKA22&fbclid=PAZXh0bgNhZW0CMTEAAaYpdYdcQ7-99gsq7dYCosbwVFcFKP41phpjVDB8FVinNhLXMSnzUwwf6D8_aem__DYqL0Ae_GxaAatORxLQqw",
    }
    // Add more sponsor logos here
  ];

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Paddling Paws - Become a Sponsor</title>
        <meta
          name="description"
          content="Support Paddling Paws by becoming a sponsor and help us rescue more animals."
        />
        <meta
          name="keywords"
          content="animal rescue sponsors, nonprofit sponsorship, support animals, Paddling Paws sponsorship"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Paddling Paws - Become a Sponsor" />
        <meta
          property="og:description"
          content="Support Paddling Paws by becoming a sponsor and help us rescue more animals."
        />
        <meta
          property="og:image"
          content="https://paddlingpaws.org/static/media/paddling_paws_hug_2.75ebef04ccbc4c38c2a8.png"
        />
        <meta
          property="og:url"
          content="https://www.paddlingpaws.org/sponsors"
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Paddling Paws",
                        "url": "https://www.paddlingpaws.org",
                        "logo": "https://www.paddlingpaws.org/images/logo.png",
                        "sameAs": [
                            "https://www.instagram.com/paddling.paws",
                            "https://www.youtube.com/@paddlingpaws"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "info@paddlingpaws.org",
                            "contactType": "Customer Service"
                        }
                    }
                    `}
        </script>
      </Helmet>

      <h2 style={styles.title}>Become a Sponsor</h2>
      <p style={styles.text}>
        At Paddling Paws, our mission is to rescue, rehabilitate, and rehome
        abandoned, neglected, and abused dogs and cats throughout California. As
        a sponsor, you can make a significant impact on the lives of these
        animals and help us continue our vital work.
      </p>

      <h3 style={styles.subtitle}>Why Sponsor Us?</h3>
      <ul style={styles.list}>
        <li>
          Your support provides medical care, food, and shelter for animals in
          need.
        </li>
        <li>Gain visibility for your brand within our community and events.</li>
        <li>
          Demonstrate your commitment to animal welfare and social
          responsibility.
        </li>
      </ul>

      <h3 style={styles.subtitle}>Sponsorship Benefits</h3>
      <ul style={styles.list}>
        <li>Your logo featured prominently on our website.</li>
        <li>Recognition at our community paddle-outs and other events.</li>
        <li>Social media shout-outs to our growing audience.</li>
        <li>Opportunities for collaborative events and initiatives.</li>
      </ul>

      <h3 style={styles.subtitle}>How to Become a Sponsor</h3>
      <p style={styles.text}>
        If you're interested in becoming a sponsor, we'd love to hear from you!
        Please reach out to us at{" "}
        <a href="mailto:info@paddlingpaws.org" style={styles.emailLink}>
          info@paddlingpaws.org
        </a>{" "}
        to discuss sponsorship opportunities tailored to your organization's
        goals.
      </p>

      <h3 style={styles.subtitle}>Our Sponsors</h3>
      <p style={styles.text}>
        We are grateful for the support of our sponsors who make our work
        possible.
      </p>

      {/* Sponsor Logos */}
      <div style={styles.logoContainer}>
        {sponsorLogos.map((logo, index) => (
          <div key={index} style={styles.logo}>
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img src={logo.src} alt={logo.alt} style={styles.logoImage} />
            </a>
          </div>
        ))}

        {/* Placeholder for future sponsors
        <div style={styles.logoPlaceholder}>
          <p>Your Logo Here</p>
        </div> */}
      </div>

      <p style={styles.text}>
        Together, we can make a difference in the lives of countless animals.
        Thank you for considering a sponsorship with Paddling Paws.
      </p>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  title: {
    fontSize: "32px",
    color: "#5180C0",
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "24px",
    color: "#5180C0",
    marginTop: "30px",
    marginBottom: "10px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "1.6",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  emailLink: {
    color: "#5180C0",
    textDecoration: "none",
    fontWeight: "bold",
  },
  logoContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "20px",
  },
  logo: {
    margin: "10px",
  },
  logoImage: {
    maxWidth: "200px",
    maxHeight: "150px",
  },
  logoPlaceholder: {
    width: "200px",
    height: "150px",
    border: "2px dashed #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    color: "#777",
    fontSize: "18px",
  },
};

export default Sponsors;
