import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Define 'process.env' if it's not defined in the global scope
if (typeof window !== "undefined" && typeof window.process === 'undefined') {
    window.process = {
        env: { NODE_ENV: 'development' } // Define other environment variables as needed
    };
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
