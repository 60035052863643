import React from "react";
import { Helmet } from "react-helmet";

import eberle_logo from "../assets/images/wine_logos/eberle.jpg";
import bella_luna_logo from "../assets/images/wine_logos/Bella-Luna-Estate-Winery.jpg";
import ulloa_logo from "../assets/images/wine_logos/UlloaCellars.png";

function Wine() {
  const wineryLogos = [
    {
      src: eberle_logo,
      alt: "Eberle Winery Logo",
      link: "https://www.eberlewinery.com/club/",
    },
    {
      src: bella_luna_logo,
      alt: "Bella Luna Winery Logo",
      link: "https://www.bellalunawine.com/wine-club/",
    },
    {
      src: ulloa_logo,
      alt: "Ulloa Cellars Logo",
      link: "https://ulloacellars.com/wine-club",
    },
    // Add more wine logos here
  ];

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Paddling Paws - Wine That Saves Lives</title>
        <meta
          name="description"
          content="Enjoy exceptional wines and make a real difference. Join one of our partner wine clubs and support Paddling Paws in rescuing animals in need."
        />
        <meta
          name="keywords"
          content="wine club, animal rescue, support animals, Paddling Paws, wine fundraising, wine with purpose"
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Paddling Paws - Wine That Saves Lives"
        />
        <meta
          property="og:description"
          content="Sip for a cause! Join a wine club that supports Paddling Paws and help rescue animals while enjoying world-class wines."
        />
        <meta
          property="og:image"
          content="https://www.paddlingpaws.org/static/media/wine_with_purpose.jpg"
        />
        <meta property="og:url" content="https://www.paddlingpaws.org/wine" />
        <meta property="og:type" content="website" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Paddling Paws",
                        "url": "https://www.paddlingpaws.org",
                        "logo": "https://www.paddlingpaws.org/images/logo.png",
                        "sameAs": [
                            "https://www.instagram.com/paddling.paws",
                            "https://www.youtube.com/@paddlingpaws"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "info@paddlingpaws.org",
                            "contactType": "Customer Service"
                        }
                    }
                    `}
        </script>
      </Helmet>

      <h2 style={styles.title}>Wine That Warms the Heart and Helps the Paws</h2>

      <h3 style={styles.subtitle}>Because Every Bottle Should Give Back</h3>

      <p style={styles.cta}>
        <a href="#wine-clubs" style={styles.ctaLink}>
          Join Today and Start Sipping for a Cause!
        </a>
      </p>

      <p style={styles.text}>
        Why settle for just another wine club when you can be part of something
        meaningful? By joining one of our partner wine clubs, you're not just
        treating yourself—you’re helping to create a future where every animal
        has a reason to wag their tail.
      </p>

      <p style={styles.text}>
        Indulge in exceptional wines while making a real difference. Our wine
        club partnerships offer more than just award-winning wines—they're a way
        to give back. A portion of your wine club total goes directly to{" "}
        <strong>Paddling Paws</strong>, supporting animals who need a helping
        hand (or paw). Sip for good and help us save lives today!
      </p>

      <h3 style={styles.subtitle}>Our Wine Club Partners</h3>

      <p style={styles.text}>
        Explore our exclusive wine club partnerships below. Each membership in a
        Paddling Paws partnered wine club helps rescue and care for animals in
        need, giving every sip a greater purpose.
      </p>

      {/* Winery Logos */}
      <div id="wine-clubs" style={styles.logoContainer}>
        {wineryLogos.map((logo, index) => (
          <div key={index} style={styles.logo}>
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img src={logo.src} alt={logo.alt} style={styles.logoImage} />
            </a>
          </div>
        ))}
      </div>

      <p style={styles.text}>
        Together, we can make a difference in the lives of countless animals.
        Thank you for supporting Paddling Paws through our Wine Club
        collaborations.
      </p>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  title: {
    fontSize: "32px",
    color: "#5180C0",
    marginBottom: "20px",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "24px",
    color: "#5180C0",
    marginTop: "30px",
    marginBottom: "10px",
    textAlign: "center",
  },
  text: {
    fontSize: "16px",
    lineHeight: "1.6",
    textAlign: "center",
  },
  cta: {
    marginTop: "20px",
    marginBottom: "20px",
    textAlign: "center",
  },
  ctaLink: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#5180C0",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
    fontSize: "16px",
  },
  logoContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "20px",
  },
  logo: {
    margin: "10px",
  },
  logoImage: {
    maxWidth: "200px",
    maxHeight: "150px",
  },
};

export default Wine;
