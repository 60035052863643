import React from "react";

function Adopt() {
  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      <h2>Adopt Your New Best Friend</h2>
      <p>
        Adopting a pet is a life-changing experience—not just for you, but for
        the pet that finds a loving forever home. At Paddling Paws, we are here
        to help you find your perfect match.
      </p>
      <p>
        Whether you have your eye on one of our adoptable pets or are looking
        for a specific type of pet we don’t currently have, we’d love to assist
        you in your search. By filling out our Adoption Application, you can
        either express interest in one of our available animals or let us know
        what you're looking for. We’ll do our best to match you with a pet that
        fits your family and lifestyle.
      </p>
      <p>
        Ready to take the next step? Fill out the Adoption Application below,
        and let’s find your new best friend together!
      </p>
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingTop: "150%",
          marginBottom: "20px",
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfe76Tu2mYNuDBF7nFfGWFq5kJGkI4enapFpwymER457Kou0g/viewform?embedded=true"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "0",
          }}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Adoption Application"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default Adopt;