import React from "react";

function Foster() {
  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      <h2>Fostering with Us</h2>
      <p>
        If you're interested in fostering with Paddling Paws, please fill out the
        form below:
      </p>
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingTop: "150%",
          marginBottom: "20px",
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdvvirYwfXZ2_hiIYDaVIYOw_hjW5UmHdj31g-_TNvmzpFzyw/viewform?embedded=true"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "0",
          }}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Foster Interest Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default Foster;
