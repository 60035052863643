import React, { useState } from "react";
import { Link } from "react-router-dom";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="menu-icon" onClick={handleToggle}>
        {isOpen ? "✖" : "☰"}
      </div>
      <ul className={isOpen ? "nav-links open" : "nav-links"}>
        {isOpen && (
          <li className="close-menu">
            <button onClick={closeMenu} className="close-button">
              ✖
            </button>
          </li>
        )}

        <li>
          <Link to="/" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={closeMenu}>
            About
          </Link>
        </li>
        <li>
          <Link to="/events" onClick={closeMenu}>
            Events
          </Link>
        </li>
        <li>
          <Link to="/animals" onClick={closeMenu}>
            Animals
          </Link>
        </li>
        <li>
          <Link to="/adopt" onClick={closeMenu}>
            Adopt
          </Link>
        </li>
        <li>
          <Link to="/foster" onClick={closeMenu}>
            Foster
          </Link>
        </li>
        <li>
          <Link to="/sponsors" onClick={closeMenu}>
            Sponsors
          </Link>
        </li>
        <li>
          <Link to="/donate" onClick={closeMenu}>
            Donate
          </Link>
        </li>
        <li>
          <Link to="/interest" onClick={closeMenu}>
            Interested?
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
