import React from 'react';
import PurpleWitchesPaddleEvent from '../assets/images/events/PurpleCutePetHalloweenPartyInvitationPoster.png';

const events = [
    {
        id: 1,
        title: 'Witches and Warlocks Paddle',
        date: 'October 26, 2024',
        time: '12:00 PM - 4:00 PM',
        location: 'Coleman Park, Morro Bay',
        image: PurpleWitchesPaddleEvent,
    },
    // {
    //     id: 2,
    //     title: 'Adopt-a-Thon at Central Park',
    //     date: 'November 5, 2024',
    //     time: '10:00 AM - 2:00 PM',
    //     location: 'Central Park, San Luis Obispo',
    //     image: 'https://via.placeholder.com/300x200', 
    // },
    // {
    //     id: 3,
    //     title: 'Holiday Pet Photos Event',
    //     date: 'December 12, 2024',
    //     time: '1:00 PM - 3:00 PM',
    //     location: 'Pismo Beach Dog Park',
    //     image: 'https://via.placeholder.com/300x200',
    // },
];

function Events() {
    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Upcoming Events</h2>
            <div style={styles.eventList}>
                {events.map((event) => (
                    <div key={event.id} style={styles.eventItem}>
                        <img src={event.image} alt={event.title} style={styles.eventImage} />
                        <h3 style={styles.eventTitle}>{event.title}</h3>
                        <p style={styles.eventDate}>{event.date}</p>
                        {event.time && <p style={styles.eventTime}><strong>Time:</strong> {event.time}</p>}
                        {event.location && <p style={styles.eventLocation}><strong>Location:</strong> {event.location}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
}

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    eventList: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        maxHeight: '600px',
        paddingRight: '10px',
    },
    eventItem: {
        marginBottom: '20px',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px',
    },
    eventImage: {
        width: '100%',
        maxWidth: '600px',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
    },
    eventTitle: {
        fontSize: '1.5rem',
        textAlign: 'center',
        marginTop: '10px',
    },
    eventDate: {
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    eventTime: {
        fontSize: '1rem',
        textAlign: 'center',
    },
    eventLocation: {
        fontSize: '1rem',
        textAlign: 'center',
    },
};

export default Events;
